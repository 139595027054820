<template>
    <div>
        <div align="center">
            <h4>Annulla pratica</h4>
            <hr>
        </div>

        <div class="row justify-center">

            <div class="col-12 col-md-5 q-px-md">
                <h5 class="q-mt-sm">Dati della pratica da annullare</h5>
                <q-list separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Area:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.area}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Stato della pratica:</q-item-label>
                            <q-item-label caption>{{getStatoPratica}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Cliente:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.name}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Unità operativa richiedente:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.unita_operativa}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Persona operativa:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.operatore}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Numero pratica:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.numero}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Numero interno:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.numero_interno}}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Data di decorrenza richiesta:</q-item-label>
                            <q-item-label caption>{{getDataDecorrenzaPratica}}</q-item-label>
                        </q-item-section>
                    <!--    <q-item-section>
                            <q-item-label>Frazionamento del premio:</q-item-label>
                            <q-item-label caption>{{getFrazionamentoPratica}}</q-item-label>
                        </q-item-section> //-->
                    </q-item>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Note:</q-item-label>
                            <q-item-label caption>{{annotazione}}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>

            <div class="col-12 col-md-5 q-px-md">
                <h5 class="q-mt-sm">Descrivi il motivo dell'annullamento</h5>

                <q-input
                    outlined
                    square
                    name="descrizione"
                    v-model="descrizione_motivo_annullo"
                    filled
                    type="textarea"
                />
            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="TORNA AL MENU"
                        color="blue-grey"
                        icon="undo"
                        size="md"
                        icon_align="left"
                        @click.native="onIndietroClicked()"
                    />
                </div>

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="ANNULLA PRATICA"
                        color="blue-grey"
                        icon="mdi-delete-empty"
                        size="md"
                        icon_align="right"
                        @click.native="onAnnullaClicked()"
                    />
                </div>

            </div>

        </div>
        <br><br><br><br><br><br>
    </div>
</template>

<script>
    import { mapFields} from 'vuex-map-fields';
    import { mapState,mapActions } from 'vuex';
    import commonLib from "@/libs/commonLib";
    import QQButton from "@/components/QQButton.vue";

    export default {
        name: "AnnullaPratica",
        components: {
            QQButton
        },
        data() {
            return {
                descrizione_motivo_annullo: "",
                annotazione: ""
            }
        },
        methods: {
            ...mapActions({
                setAnnullaPratica: "gestionePratiche/setAnnullaPratica"
            }),
            onIndietroClicked() {
                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
            },
            async onAnnullaClicked() {
                // Verifica se scrivono cavolate
                var numero_parole = this.descrizione_motivo_annullo.split(" ");
                var len = this.descrizione_motivo_annullo.length;

                if (len == 0) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Devi inserire il motivo dell`annullo',
                    });

                    return;
                }

                if ((len < 6) || (numero_parole.length < 3)) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Descrizione insufficiente. Puoi fare di meglio',
                    });

                    return;
                }

                var numero_pratica = this.formPratica.preventivo.id;
                await this.setAnnullaPratica({numero_pratica: numero_pratica,note: this.descrizione_motivo_annullo});

                this.$router.push({name : "Pratiche"});
            }
        },
        computed: {
            ...mapFields("gestionePratiche", {
                RecordSelezionato: "RecordSelezionato"
            }),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPratica: state => state.gestionePratiche.formPratica
            }),
            getStatoPratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.stato_pratica.toUpperCase();
            },
            getDataDecorrenzaPratica() {
                if (commonLib.isEmpty(this.formPratica)) return "";
                return this.formPratica.preventivo.decorrenza;
            },
            getFrazionamentoPratica() {
                //if (commonLib.isEmpty(this.formPratica)) return "";
                //return this.formPratica.preventivo.frazionamento.toUpperCase();

                return "";
            }
        },
        async mounted() {
            this.annotazione = "";
            this.descrizione_motivo_annullo = "";
        },
        async activated() {
            this.annotazione = "";
            this.descrizione_motivo_annullo = "";
        }
    }

</script>
